<template>
  <div class="means">
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick" class="el-nav"> -->
    <!-- <el-tab-pane :label="'图库 ' + imgNum" name="gallery"></el-tab-pane>
      <el-tab-pane :label="'资料 ' + dataNum" name="means"></el-tab-pane> -->

    <el-form :model="MaterialsForm" ref="coursesForm" label-position="right" label-width="150px" class="addForm">
      <el-select v-model="MaterialsForm.options" placeholder="名称" class="search-type search-type_first">
        <el-option v-for="item in nameList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
      <el-input v-model="MaterialsForm.keywords" placeholder="请输入查询条件" class="search-input"></el-input>

      <div class="">
        <span class="type_text" style="margin-right: 10px">状态</span>
        <el-select :clearable="true" v-model="MaterialsForm.type" @change="chose_type" placeholder="请选择" class="search-type">
          <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>
      <el-button type="primary" class="search-btn search-btn-query" @click="screenSubmit">查询</el-button>
      <el-button class="search-btn reset" @click="screenDefault">重置</el-button>
    </el-form>
    <div class="statistics">
      共有
      <span>{{ total }}</span>
      条数据
    </div>
    <el-table
      v-loading="loading"
      class="table"
      ref="multipleTable"
      :data="listData"
      tooltip-effect="dark"
      :header-cell-style="{ background: '#F6F8F9', color: '#1C1F21', fontWeight: '400' }"
      border
    >
      <el-table-column label="名称" width="200px">
        <template slot-scope="scope">
          <div class="el-name">{{ scope.row.name }}</div>
        </template>
      </el-table-column>
      <el-table-column label="格式" prop="ext">
        <template slot-scope="scope">
          <div class="el-name">{{ scope.row.ext }}</div>
        </template>
      </el-table-column>
      <el-table-column label="大小" prop="size">
        <template slot-scope="scope">
          <div class="el-name">{{ scope.row.size }}</div>
        </template>
      </el-table-column>
      <el-table-column label="查看次数" prop="view_number">
        <template slot-scope="scope">
          <div class="el-name">{{ scope.row.view_number }}</div>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="status">
        <template slot-scope="scope">
          <div :class="scope.row.status === 1 ? 'avtive-name' : 'el-name'">{{ scope.row.status === 1 ? '发布' : '关闭' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="上传时间" prop="created_at" width="170">
        <template slot-scope="scope">
          <div class="el-name">{{ scope.row.created_at }}</div>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="small" class="table-edit" @click="updata(scope.row)">编辑</el-button>
          <span style="color: #d0d6de; margin: 0 8px">|</span>
          <el-dropdown @command="handleCommand" trigger="click">
            <span class="table-edit table-span"> 更多 </span>
            <el-dropdown-menu slot="dropdown" class="drop">
              <el-dropdown-item :command="['view', scope.row]"><i class="el-icon-check" style="opacity: 0"></i>查看</el-dropdown-item>
              <el-dropdown-item v-if="scope.row.status === -1" :command="['del', scope.row]"><i class="el-icon-check" style="opacity: 0"></i>删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <!-- </el-tabs> -->
    <el-pagination
      class="page"
      background
      v-if="listData.length !== 0 && total > 10"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[20, 50, 100]"
      :page-size="pageSize"
      layout="prev, pager, next,total, jumper"
      :total="total"
    >
    </el-pagination>

    <el-dialog
      class="dialog"
      title="编辑名称"
      :visible.sync="dialogFormVisible"
      v-if="dialogFormVisible"
      top="calc( 50vh - 150px )"
      width="540px"
      :destroy-on-close="true"
      @close="close('from')"
    >
      <!--  表单-->
      <el-form label-position="left" label-width="60px" class="form" ref="from" :rules="rules" :model="form">
        <el-form-item label="名称" prop="name">
          <el-input v-model.trim="form.name" maxlength="30"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="close('from')">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="preservation('from')">保存</el-button>
      </span>
    </el-dialog>

    <!--    删除弹框-->
    <el-dialog class="delDiolog" :visible.sync="isdelDialogVisible" top="calc( 50vh - 150px )" icon="el-icon-warning" :destroy-on-close="true" width="360px" center>
      <div class="dioTitle"><i class="el-icon-warning"></i> 这是您操作比较重要的数据</div>
      <span>你确定要删除该资料么？</span>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="isdelDialogVisible = false">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="putTagStatus">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      imgNum: 0,
      dataNum: 0,
      loading: false,
      uploadactiveName: 'upload',
      activeName: 'means',
      dialogFormVisible: false,
      isdelDialogVisible: false,
      fileUploadDialog: false,
      page: 1,
      pageSize: 10,
      total: 0,
      file_url: [],
      name: '',
      parentId: '',
      id: '',
      title: '',
      // showNum: false,
      MaterialsForm: {
        keywords: '',
        options: 'name',
        type: '',
      },
      form: {
        name: '',
      },
      nameList: [
        {
          label: '名称',
          value: 'name',
        },
      ],
      typeList: [
        {
          label: '发布',
          value: 1,
        },
        {
          label: '关闭',
          value: -1,
        },
      ],
      listData: [],
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.getInfoList();
  },
  mounted() {},
  methods: {
    // tab切换
    handleClick(tab) {
      if (tab.name === 'gallery') {
        this.$router.push('/source');
      } else {
        // this.getInfoList();
        this.screenDefault();
      }
    },
    getInfoList() {
      this.getTagNum();
      this.loading = true;
      let url = `${this.$api.source}?page=${this.page}&pageSize=10&options=${this.MaterialsForm.options}&keywords=${this.MaterialsForm.keywords}&status=${this.MaterialsForm.type}&type=info`;
      this.$http.get(url, true).then((res) => {
        if (res.data.code === 200000) {
          this.loading = false;
          this.listData = res.data.data.data;
          this.total = res.data.data.meta.total;
        }
      });
    },
    getTagNum() {
      let url = this.$api.categories;
      let secNum = 0;
      this.$http.get(url, true).then((res) => {
        secNum = res.data.data.info;
        this.dataNum = secNum > 9999 ? (secNum / 10000).toFixed(2) + 'w' : secNum;
        this.$emit('screenInfo', this.dataNum);
      });
    },
    chose_type(val) {
      console.log(val);
      this.MaterialsForm.type = val;
    },
    // 查询
    screenSubmit() {
      this.page = 1;
      this.getInfoList();
    },
    // 重置
    screenDefault() {
      this.MaterialsForm.keywords = '';
      this.MaterialsForm.type = '';
      this.page = 1;
      this.getInfoList();
    },
    upload() {
      this.fileUploadDialog = true;
    },
    // 打开弹框
    updata(row) {
      this.dialogFormVisible = true;
      this.form.name = row.name_update;
      this.id = row.id;
    },
    // 保存
    preservation(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.name.length > 30) {
            this.$message({
              type: 'warning',
              message: '最多输入30个字符',
            });
          } else {
            let url = `${this.$api.source}/${this.id}`;
            this.$http.patch(url, { name: this.form.name }, true).then((res) => {
              if (res.data.code === 200000) {
                this.$message({
                  type: 'success',
                  message: '操作成功!',
                });
              }
              this.close(formName);
              this.getInfoList();
            });
          }
        } else {
          return false;
        }
      });
    },
    // 关闭弹框
    close(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible = false;
    },

    //  下拉选择
    handleCommand(command) {
      this.id = command[1].id;
      if (command[0] === 'view') {
        if (command[1].mime_type === 'pdf') {
          window.open(command[1].path);
        } else {
          let url = command[1].path;
          let name = `${command[1].name}.${command[1].ext}`;
          // console.log(url);
          this.$util.downloadRes(url, name);
        }
      } else {
        this.isdelDialogVisible = true;
      }
    },
    // 删除
    putTagStatus() {
      let url = `${this.$api.source}/${this.id}`;
      this.$http.del(url, true).then((res) => {
        if (res.data.code === 200000) {
          // this.showNum = true;
          this.getInfoList();
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
          this.isdelDialogVisible = false;
        }
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.page = val;
      this.getInfoList();
    },
    resultImg(msg) {
      let size = [];
      let file_name = [];
      msg.info.forEach((e) => {
        size.push(e.size);
        file_name.push(e.name);
      });
      this.file_url = msg.url;
      let data = {
        url: this.file_url,
        size,
        file_name,
      };
      let url = `${this.$api.DiscoverContents}/${this.parentId}/${this.$api.info}`;
      this.$http.post(url, data, true).then((res) => {
        if (res.data.code === 200000) {
          this.getInfoList();
        }
      });
    },
  },
  components: {},
};
</script>
<style lang="less" scoped>
@import '~assets/less/article/materials.less';
.rule .el-nav .el-form .search-type {
  width: 90px;
}
/deep/.el-tabs__content {
  padding-top: 15px;
}
/deep/.el-form-item {
  width: 100%;
}
</style>
