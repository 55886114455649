<template>
  <div class="rule">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="el-nav">
      <el-tab-pane :label="'图库 ' + imgNum" name="gallery">
        <el-form :model="MaterialsForm" ref="coursesForm" label-position="right" label-width="150px" class="addForm">
          <el-select v-model="MaterialsForm.options" placeholder="编号" class="search-type search-type_first" @change="choseOptions">
            <el-option v-for="item in nameList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-input v-model="MaterialsForm.keywords" placeholder="请输入查询条件" class="search-input"></el-input>
          <div class="">
            <span class="type_text">状态</span>
            <el-select v-model="MaterialsForm.status" placeholder="请选择" :clearable="true" class="search-type" @change="chose_status" @clear="showSelection = false">
              <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="date_box">
            <span class="type_text">操作时间</span>
            <el-date-picker
              class="picker"
              v-model="dateRange"
              type="daterange"
              :clearable="true"
              align="right"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @mouseover.native="setDateIcon"
              @mouseleave.native="dateVisableArray = false"
            ></el-date-picker>
            <i class="el-icon-date" :class="dateVisableArray ? 'data_none_icon' : 'data_icon'"></i>
          </div>
          <el-button type="primary" class="search-btn" @click="screenSubmit">查询</el-button>
          <upload-file @successData="successData" :multiple="true" :folder="'course/images'" :btn_upload="true" :limit="5"></upload-file>
        </el-form>
        <div class="total vertical-center">
          <div class="statistics">
            共有
            <span>{{ total }}</span>
            条数据
          </div>
          <el-button type="text" class="total-right vertical-center" v-if="listData.length && piDel && showSelection && MaterialsForm.status !== ''" @click="delImg('del')">
            <img src="~assets/images/index/del.png" alt="" style="vertical-align: bottom" class="del" />
            <img src="~assets/images/index/del-hover.png" alt="" style="vertical-align: bottom" class="del-hover" />
            <span style="margin-left: 8px">批量删除</span>
          </el-button>
          <el-button type="text" class="total-right vertical-center" v-if="listData.length && piDel && showSelection && MaterialsForm.status !== ''" @click="delImg('release')">
            <img src="~assets/images/index/release.png" alt="" style="vertical-align: bottom" class="del" />
            <img src="~assets/images/index/release-hover.png" alt="" style="vertical-align: bottom" class="del-hover" />
            <span style="margin-left: 8px">批量发布</span>
          </el-button>
          <el-button type="text" class="total-right vertical-center" v-if="listData.length && piClose && showSelection && MaterialsForm.status !== ''" @click="closeImg">
            <img src="~assets/images/index/close.png" alt="" style="vertical-align: bottom" class="del" />
            <img src="~assets/images/index/close-hover.png" alt="" style="vertical-align: bottom" class="del-hover" />
            <span style="margin-left: 8px">批量关闭</span>
          </el-button>
        </div>

        <el-table
          v-loading="loading"
          class="table"
          ref="multipleTable"
          :data="listData"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
          :header-cell-style="{ background: '#F6F8F9', color: '#1C1F21', fontWeight: '400' }"
          border
        >
          <el-table-column v-if="MaterialsForm.status !== '' && listData.length !== 0 && showSelection" type="selection" :selectable="checkSelecTable" label="全选" width="90" align="center">
          </el-table-column>
          <el-table-column label="编号" width="90px">
            <template slot-scope="scope">
              <div class="el-name">{{ scope.row.id }}</div>
            </template>
          </el-table-column>
          <el-table-column label="名称" prop="ext">
            <template slot-scope="scope">
              <div class="user_info">
                <div class="img">
                  <img :src="scope.row.path" alt="" />
                </div>

                <div class="img_info">
                  <p class="imgname">{{ scope.row.name }}</p>
                  <p class="size">大小： {{ scope.row.size }}</p>
                  <div class="state_btn" :class="scope.row.status === 0 ? 'state_btn' : scope.row.status === 1 ? 'release_btn' : 'close_btn'">
                    {{ scope.row.status === 0 ? '草稿' : scope.row.status === -1 ? '已关闭' : scope.row.status === -2 ? '已删除' : '已发布' }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="操作人" width="170">
            <template slot-scope="scope">
              <div class="el-name">{{ scope.row.admin ? scope.row.admin.nickname : '' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作时间" prop="updated_at" width="170">
            <template slot-scope="scope">
              <div class="el-name">{{ scope.row.updated_at }}</div>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center" width="150">
            <template slot-scope="scope">
              <el-button type="text" size="small" class="table-edit" @click="updata(scope.row)">编辑</el-button>
              <span style="color: #d0d6de; margin: 0 8px">|</span>
              <el-dropdown @command="handleCommand" trigger="click">
                <span class="table-edit table-span"> 更多 </span>
                <el-dropdown-menu slot="dropdown" class="drop">
                  <el-dropdown-item v-if="scope.row.status === 0 || scope.row.status === -1" :command="['release', scope.row]"
                    ><i class="el-icon-check" style="opacity: 0"></i>发布</el-dropdown-item
                  >

                  <el-dropdown-item v-if="scope.row.status !== 0" :command="['view', scope.row]"><i class="el-icon-check" style="opacity: 0"></i>查看</el-dropdown-item>

                  <el-dropdown-item v-if="scope.row.status === 0 || scope.row.status === -1" :command="['del', scope.row]"
                    ><i class="el-icon-check" style="opacity: 0"></i>删除</el-dropdown-item
                  >

                  <el-dropdown-item v-if="scope.row.status === 1" :command="['close', scope.row]"><i class="el-icon-check" style="opacity: 0"></i>关闭</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="page"
          background
          v-if="listData.length !== 0 && total > 10"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[20, 50, 100]"
          :page-size="pageSize"
          layout="prev, pager, next,total, jumper"
          :total="total"
        >
        </el-pagination>
        <el-dialog
          class="dialog"
          title="编辑名称"
          :visible.sync="dialogFormVisible"
          v-if="dialogFormVisible"
          top="calc( 50vh - 150px )"
          width="540px"
          :destroy-on-close="true"
          @close="close('from')"
        >
          <!--  表单-->
          <el-form label-position="left" label-width="60px" class="form" ref="from" :rules="rules" :model="form">
            <el-form-item label="名称" prop="name">
              <el-input v-model.trim="form.name" maxlength="30"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer form-operation">
            <el-button class="form-operation-item" round @click="close('from')">取 消</el-button>
            <el-button class="form-operation-item" round type="primary" @click="preservation('from')">保存</el-button>
          </span>
        </el-dialog>
        <!--    删除弹框-->
        <el-dialog
          class="delDiolog"
          :visible.sync="isdelDialogVisible"
          top="calc( 50vh - 150px )"
          icon="el-icon-warning"
          :destroy-on-close="true"
          width="360px"
          center
          :close-on-click-modal="false"
          @close="closeDiog"
        >
          <div class="dioTitle"><i class="el-icon-warning"></i> 这是您操作比较重要的数据</div>
          <span>你确定要{{ condition }}该图片么？</span>
          <span slot="footer" class="dialog-footer form-operation">
            <el-button class="form-operation-item" round @click="closeDiog">取 消</el-button>
            <el-button v-if="!batch" class="form-operation-item" round type="primary" @click="putTagStatus">确 认</el-button>
            <el-button v-else class="form-operation-item" round type="primary" @click="putbatch">确 认</el-button>
          </span>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane :label="'资料 ' + dataNum" name="means">
        <Means ref="means" @screenInfo="screenInfo"></Means>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import UploadFile from 'components/uploadFile';
import Means from 'view/content/source/Means';

export default {
  props: {},
  data() {
    return {
      imgNum: 0,
      dataNum: 0,
      loading: false,
      uploadactiveName: 'upload',
      activeName: 'gallery',
      dialogFormVisible: false,
      isdelDialogVisible: false,
      fileUploadDialog: false,
      dateVisableArray: false,
      condition: '删除',
      page: 1,
      pageSize: 10,
      total: 0,
      file_url: [],
      name: '',
      parentId: '',
      id: '',
      title: '',
      start_date: '',
      end_date: '',
      dateRange: [],
      multipleSelection: [],
      status: null,
      delimgfir: '',
      delimgsec: '',
      closeimgsec: '',
      delCheckShow: false,
      closeCheckShow: false,
      batch: false,
      piDel: false,
      piClose: false,
      showSelection: false,
      ids: [],
      MaterialsForm: {
        keywords: '',
        options: 'id',
        status: '',
        // type: '',
      },
      form: {
        name: '',
      },
      nameList: [
        {
          label: '编号',
          value: 'id',
        },
        {
          label: '名称',
          value: 'name',
        },
      ],
      typeList: [
        {
          label: '草稿',
          value: 0,
        },
        {
          label: '已发布',
          value: 1,
        },
        // {
        //   label: '已删除',
        //   value: -2,
        // },
        {
          label: '已关闭',
          value: -1,
        },
      ],
      listData: [],
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      },
    };
  },

  created() {
    this.getImgList();
  },
  mounted() {},
  methods: {
    chose_status(val) {
      console.log(this.showSelection);
      this.showSelection = true;
      this.MaterialsForm.status = val;
      this.screenSubmit();
    },
    choseOptions(val) {
      this.MaterialsForm.options = val;
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    // 批量删除
    delImg(type) {
      if (this.multipleSelection.length === 0) {
        this.$message({
          type: 'warning',
          message: '请先选择数据',
        });
      } else {
        this.batch = true;
        this.multipleSelection.forEach((e) => {
          this.ids.push(e.id);
        });
        if (type === 'del') {
          this.condition = '删除';
          this.isdelDialogVisible = true;
        } else {
          this.condition = '发布';
          this.isdelDialogVisible = true;
        }
      }

      console.log(this.ids);
    },
    closeImg() {
      if (this.multipleSelection.length !== 0) {
        this.batch = true;
        this.multipleSelection.forEach((e) => {
          this.ids.push(e.id);
        });

        this.condition = '关闭';
        this.isdelDialogVisible = true;
      } else {
        this.$message({
          type: 'warning',
          message: '请先选择数据',
        });
      }
    },
    // 判断是否可以选中
    checkSelecTable(row) {
      if ((this.MaterialsForm.status === 0 || this.MaterialsForm.status === -1) && this.showSelection) {
        return row.status === 0 || row.status === -1;
      } else if (this.MaterialsForm.status === 1 && this.showSelection) {
        return row.status === 1;
      } else {
        return true;
      }
    },
    // 新增图片
    successData(msg) {
      // console.log(msg);
      let size = [];
      let file_name = [];
      msg.info.forEach((e) => {
        size.push(e.size);
        file_name.push(e.name);
      });
      this.file_url = msg.url;
      let data = {
        url: this.file_url,
        size,
        file_name,
      };
      let url = this.$api.source;
      this.$http.post(url, data, true).then((res) => {
        if (res.data.code === 200000) {
          // this.$message({
          //   type: 'success',
          //   message: '新增成功!',
          // });
          // this.showNum = true;
          this.getImgList();
        }
      });
      // console.log(data);
    },
    // 样式切换
    setDateIcon() {
      if (this.dateRange == '' || this.dateRange == null) {
        this.dateVisableArray = false;
        return;
      }
      this.dateVisableArray = true;
    },
    // tab切换
    handleClick(tab) {
      if (tab.name === 'means') {
        this.$refs.means.screenDefault();
      } else {
        this.page = 1;
        this.MaterialsForm.keywords = '';
        this.MaterialsForm.status = '';
        this.getImgList();
      }
    },
    getImgList() {
      this.getTagNum();
      this.loading = true;
      let url = `${this.$api.source}?page=${this.page}&pageSize=10&options=${this.MaterialsForm.options}&keywords=${this.MaterialsForm.keywords}&type=image&status=${this.MaterialsForm.status}&start_date=${this.start_date}&end_date=${this.end_date}`;
      this.$http.get(url, true).then((res) => {
        if (res.data.code === 200000) {
          this.loading = false;

          this.listData = res.data.data.data;
          this.total = res.data.data.meta.total;
          this.batch = false;
          this.delCheckShow = false;
          this.closeCheckShow = false;
          this.ids = [];
          this.multipleSelection = [];
          this.$refs.multipleTable.clearSelection();
        }
      });
    },

    getTagNum() {
      let url = this.$api.categories;
      let firstNum = 0;
      let secNum = 0;
      this.$http.get(url, true).then((res) => {
        firstNum = res.data.data.image;
        secNum = res.data.data.info;
        this.imgNum = firstNum > 9999 ? (firstNum / 10000).toFixed(2) + 'w' : firstNum;
        this.dataNum = secNum > 9999 ? (secNum / 10000).toFixed(2) + 'w' : secNum;
      });
    },
    // 查询
    screenSubmit() {
      if (this.MaterialsForm.status === 0 || this.MaterialsForm.status === -1) {
        this.piDel = true;
        this.piClose = false;
      } else if (this.MaterialsForm.status === 1) {
        this.piDel = false;
        this.piClose = true;
      } else {
        this.piDel = false;
        this.piClose = false;
      }
      this.page = 1;
      if (this.dateRange === null) {
        this.dateRange = [];
      }
      let start_date = this.dateRange.length !== 0 ? this.dateRange[0] : '';
      this.start_date = start_date ? `${start_date} 00:00:00` : '';
      console.log();
      let end_date = this.dateRange.length !== 0 ? this.dateRange[1] : '';
      this.end_date = start_date ? `${end_date} 23:59:59` : '';
      if (this.MaterialsForm.status !== '') {
        this.showSelection = true;
      } else {
        this.showSelection = false;
      }

      this.getImgList();
    },
    screenInfo(val) {
      this.dataNum = val;
    },
    upload() {
      this.fileUploadDialog = true;
    },
    // 打开弹框
    updata(row) {
      this.dialogFormVisible = true;
      this.form.name = row.name_update;
      this.id = row.id;
    },
    // 保存
    preservation(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.name.length > 30) {
            this.$message({
              type: 'warning',
              message: '最多输入30个字符',
            });
          } else {
            let url = `${this.$api.source}/${this.id}`;
            this.$http.patch(url, { name: this.form.name }, true).then((res) => {
              if (res.data.code === 200000) {
                this.$message({
                  type: 'success',
                  message: '操作成功!',
                });
              }
              this.close(formName);
              this.getImgList();
            });
          }
        } else {
          return false;
        }
      });
    },
    // 关闭弹框
    close(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible = false;
    },

    //  下拉选择
    handleCommand(command) {
      this.id = command[1].id;
      this.status = command[1].status;
      console.log(this.id);
      if (command[0] === 'release') {
        this.condition = '发布';
        this.isdelDialogVisible = true;
      } else if (command[0] === 'view') {
        window.open(command[1].path);
      } else if (command[0] === 'del') {
        this.condition = '删除';
        this.isdelDialogVisible = true;
      } else {
        this.condition = '关闭';
        this.isdelDialogVisible = true;
      }
    },
    // 删除
    putTagStatus() {
      console.log(this.condition);
      if (this.condition === '删除') {
        let url = `${this.$api.source}/${this.id}`;
        console.log(url);
        this.$http.del(url, true).then((res) => {
          if (res.data.code === 200000) {
            this.getImgList();
            this.$message({
              type: 'success',
              message: '删除成功!',
            });
            // this.showNum = true;
            this.isdelDialogVisible = false;
          }
        });
      } else {
        let url = `${this.$api.change_status}/${this.id}`;
        let data = {
          status: this.status,
        };
        this.$http.patch(url, data, true).then((res) => {
          if (res.data.code === 200000) {
            this.getImgList();
            this.$message({
              type: 'success',
              message: '操作成功!',
            });
            this.isdelDialogVisible = false;
          }
        });
      }
    },
    // putbatch
    putbatch() {
      let url = this.$api.batchOperation;
      let status = '';
      if (this.condition === '删除') {
        // this.showNum = true;
        status = -2;
      } else if (this.condition === '发布') {
        status = 1;
      } else {
        status = -1;
      }
      let data = {
        status,
        ids: this.ids,
      };
      console.log(data);
      this.$http.post(url, data, true).then((res) => {
        console.log(res.data.code);
        if (res.data.code === 200000) {
          console.log(this.$message);
          this.$message({
            type: 'success',
            message: '操作成功!',
          });
          this.getImgList();

          this.isdelDialogVisible = false;
        } else {
          this.$message.error('操作失败');
        }
      });
      // console.log(this.condition);
      // console.log(this.ids);
    },
    closeDiog() {
      this.isdelDialogVisible = false;
      this.batch = false;
      this.delCheckShow = false;
      this.closeCheckShow = false;
      // this.$refs.multipleTable.clearSelection();
      this.ids = [];
      // this.multipleSelection = [];
    },
    // 分页
    handleCurrentChange(val) {
      this.page = val;
      this.delCheckShow = false;
      this.closeCheckShow = false;
      this.getImgList();
    },
    resultImg(msg) {
      let size = [];
      let file_name = [];
      msg.info.forEach((e) => {
        size.push(e.size);
        file_name.push(e.name);
      });
      this.file_url = msg.url;
      let data = {
        url: this.file_url,
        size,
        file_name,
      };
      let url = `${this.$api.DiscoverContents}/${this.parentId}/${this.$api.info}`;
      this.$http.post(url, data, true).then((res) => {
        if (res.data.code === 200000) {
          this.getImgList();
        }
      });
    },
  },
  components: {
    UploadFile,
    Means,
  },
};
</script>
<style lang="less" scoped>
@import '~assets/less/source/index.less';
/deep/.el-range__icon {
  display: none;
}
/deep/.el-form-item {
  width: 100%;
}

// /deep/
</style>
<style>
.el-message {
  z-index: 10000 !important;
}
</style>
